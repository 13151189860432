
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

:root {
    --maincolor: #0075FF;
    --white: #FFFFFF;
    --black: #000000;
    --grey: #D9D9D9;
    --opacity:rgba(0, 0, 0, 0.251);
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;

}

a{
text-decoration: none !important;
color: var(--black) !important;
font-weight: 700 !important;
}

.tran{
  background-color: transparent !important;
}
.blueBack{
  background-color: var(--maincolor) !important;
  color: var(--white) !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding : 0.5rem 5rem ;
}

/* Navbar */
nav{
  /* position: fixed; */
  /* width: 90%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* animation : fadeInAnimationRight 1s ease-in-out; */
}
.logo{
  display: flex;
  align-items: center;
  justify-content: center;
  /* animation : fadeInAnimationRight 1s ease-in-out; */
}
.logowh {
  width: 128px;
  
}
.logomobile{
  display: none;
}
.iconcontact{
  display: none;
}

.contactButton button{
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
 border :3px solid var(--white);
  border-radius: 2px;
  padding: 1rem 5rem;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.contactButton button span{
  font-weight: 700;
}
.contactButton button:hover{
  background-color: var(--maincolor);
  color: var(--white);
  border :3px solid var(--maincolor);
}

/* add background to section */
.firstLandingPart{
  background-image: url("./assets/background.jpg");
  /* opacity 20% */
  /* filter: brightness(50%); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  padding : 2rem 5rem ;
  position: relative;
}

.infotext{
  animation : fadeInAnimationRight 1s ease-in-out;
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
}
.backcolortext{
  background-color: var(--maincolor);
  padding: 0.5rem 1rem;
  color: var(--white);
  width: fit-content;
 
}

.textBig{
  font-size: 3rem;
  font-weight: 700;
  margin-top: 1rem;
  color: var(--white);
}

.scrolldown{
  animation : fadeInAnimationRight 1s ease-in-out;
  position: absolute;
  bottom: 0;
  width:90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.scrolldown img{
  width:400px;
}
.scrolldownText{
  color : var(--white) !important;
  font-size: 1.2rem;
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 5px solid var(--maincolor);
}

/* poolsPart */
.poolsPart{
  overflow-x: hidden;
  min-height: 100vh;
  padding :5rem 5rem ;
}

.card{
  display: flex;
}
.card img{
  width: 45%;
  object-fit: cover;
  margin-right: 5rem;
}
.infoCard{
  display: flex;
  flex-direction: column;
  width: 45%;
}
.titleCard{
  color: var(--maincolor);
}
.bigTitleCard{
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.colorOptions{
  display: flex;
  margin-top: 1rem;
}
.colorOptions .white{
  background-color: var(--white);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 1rem;
  border :3px solid var(--grey);
}
.colorOptions .grey{
  background-color: #BABDBF;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 1rem;
  border :3px solid #BABDBF;
}
.colorOptions .darkgrey{
  background-color: #5F6062;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 1rem;
  border :3px solid #5F6062;
}
.colorOptions .yellow{
  background-color: #F6C871;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 1rem;
  border :3px solid #F6C871;
}
.colorOptions .blue{
  background-color: #7EA5D8;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 1rem;
  border :3px solid #7EA5D8;
}
.goButton{
  width: 50%;
  margin-top: 2rem;
  background-color: var(--white);
  color: var(--maincolor);
  border :3px solid var(--maincolor);
  border-radius: 2px;
  padding: 1rem 0rem;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.goButton:hover{
  background-color: var(--maincolor);
  color: var(--white);
}


.top{
  margin-top: 5rem;
}

.overload .infoCard{
  width: 40%;
}
.overload {
  justify-content: space-between;
}
.skimmer{
  justify-content: space-between;
}
.overload img{
  margin-right: 0;
}

footer{
  background-color: var(--maincolor);
  padding: 1.5rem 5rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spacel{
  height: 50vh;
}


@keyframes fadeInAnimationRight {
  0% {
      opacity: 0;
      transform: translateX(-4rem);
  }

  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

/* detail */
.detailImgPart{
  height: 50vh;
  padding : 2rem 5rem ;
  background-image: url("./assets/detail.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 5px solid var(--maincolor);
  
}

.contactImgPart{
  min-height: 100vh;
  padding : 2rem 5rem ;
  /* background-image: url("./assets/contact.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 5px solid var(--maincolor);
}

.titleDetail{
  width: 100%;
  text-align: center;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  color: var(--white);
  font-size: 2rem;
  font-weight: 700;
  margin-top: 5rem;
  background-color: var(--maincolor);
  padding: 1rem 0rem;
}
hr{
  height: 1px;
        background-color: var(--maincolor);
        border: none;
        width: 80%;
}
.detailInfoPart{
  display: flex;
  min-height: 80vh;
  padding : 5rem 5rem ;
}
.information{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.image{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image img{
  width: 100%;
}

.textType{
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 1rem;
}
.specifications, .colors, .dimensions{
  margin-top: 2rem;
}
.text{
  margin-top: 1rem;
}

/* mail */
.mailForm{
  padding-top: 10rem;
}

.titleContact{
 font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.inputlabel{
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.inputField{
  width: 100%;
  height: 50px;
  border-radius: 2px;
  border: 1px solid var(--grey);
  padding: 0rem 1rem;
  margin-bottom: 1rem;
  transition: 0.5s;
}
input:focus, textarea:focus { 
  outline: none !important;
  border-color: var(--maincolor);
}
textarea{
  width: 100%;
  height: 150px;
  border-radius: 2px;
  border: 1px solid var(--grey);
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  resize: none;

}

.btnadd{
  width: 100%;
  background-color: var(--maincolor);
  color: var(--white);
  border :3px solid var(--maincolor);
  border-radius: 2px;
  padding: 1rem 0rem;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.green{
  color : #00ae03;
}
.red{
  color : #b20000;
}
.extrabut{
  padding : 0rem 5rem;
}
.buttonCont{
  padding : 1rem 5rem;
  background-color: transparent;
  color: var(--maincolor);
  border :3px solid var(--maincolor);
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-bottom: 2rem;
}
.buttonCont:hover{
  background-color: var(--maincolor);
  color: var(--white);
}


/* tablet */
@media screen and (min-width:767px) and (max-width:1023px) {

  
}

/* phone */
@media screen and (max-width:767px) {
  .firstLandingPart{
    padding : 2rem 2rem ;
  }
  nav{
    /* border: 3px solid var(--white); */
    padding: 0rem;
  }
  .logo{
    background-color: var(--white);
    width: 100%;
    left: 0;
    justify-content: center;
    display: none;
  }

  .logomobile{
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .logomobile a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .logomobile a img{
    width: 50%;
  }

  .contactButton{
  
    
    /* left: 0; */
    left: 2rem;
    right: 2rem;
    width: auto;
    bottom: 22vh;
    position: absolute;
  }
  .contactButton button{
    /* display: none; */
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
    background-color: var(--white);
    color: var(--maincolor);
    
  }
  .infotext{
    margin-top: 0vh;
    left: 2rem;
    right: 2rem;
    position: absolute;
    /* width: 100%; */
  }
  .iconcontact{
    display: flex;
    margin-right: 5px;
    color: var(--maincolor);
  }
  .backcolortext{
    background-color: transparent;
    color: var(--white);
    /* border :3px solid var(--white); */
  top: 0;
    position: absolute;
    width: 100%;
    text-align: center;
  }
  .textBig{
    margin-top: 10vh;
    /* display: none; */
    /* font-size: 1rem; */
  }
  .scrolldown{
    position: relative;
    width: auto;
    flex-direction: column-reverse;
    align-items: center;
    /* left: 0; */
    

  }
  .logowhite{
    width: 200px !important;
    bottom: 300%;
    position: absolute;
    transform: translateY(-50%);
    display: none;

  }

  .poolsPart{
    padding :2rem 2rem ;
  }
  .card{
    flex-direction: column;
  }
  .card img{
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .bigTitleCard{
    font-size: 1.5rem;
  }
  .infoCard{
    width: 100%;
  }
  .colorOptions .white{
    margin-right: 1rem;
  }
  .colorOptions .grey{
    margin-right: 1rem;
  }
  .colorOptions .darkgrey{
    margin-right: 1rem;
  }
  .colorOptions .yellow{
    margin-right: 1rem;
  }
  .colorOptions .blue{
    margin-right: 1rem;
  }
  .overload{
    flex-direction: column-reverse;
  }
  .overload .infoCard{
    width: 100%;
  }
  .goButton{
    width: 100%;
  }

  .scrolldownText{
    background-color: transparent;
    color: var(--white) !important;
    border :3px solid var(--white) !important;
    padding: 1rem 0rem;
    font-size: 1rem;
    border : 0;
    text-align: center;
    position: absolute;
    bottom: -22vh;
    transform: translateY(-50%);
    width: 100%;
    

    border-radius: 2px;
  }
  .contactImgPart{
   
    padding : 2rem 2rem ;
  }
  .blueBack a .contactButton{
    display: none;
  }
  .detailImgPart{
    padding : 2rem 2rem ;
  }
  .detailInfoPart{
    flex-direction: column;
    padding : 2rem 2rem ;
  }
  .detailImgPart nav a .contactButton{
    display: none;
  }
  .information{
    width: 100%;
  }
  .image{
    margin-top: 2rem;
    width: 100%;
  }
  hr{
    margin-top: 2rem;
  }
  .extrabut{
    padding : 0rem 2rem;
  }
  .buttonCont{
    padding : 1rem 2rem;

  }
}